/* global toastr */

import React from 'react'
import { Link } from 'react-router-dom'

import { request } from '../lib/utils'
import { store } from '../../index'
import { Paginator } from '../lib/components'
import { BaseAdminView, stateToProps } from './base_view'
import { format_attr_value, wrapView } from '../lib/utils'


export class BaseAdminListView extends BaseAdminView {
  constructor(props) {
    super(props)
    this.updateItems = this.updateItems.bind(this)
  }

  onSearch(data) {
    store.dispatch({ type: 'admin_search', data })
  }

  updateItems(page_num) {
    const that = this;

    request({
      method: 'post',
      url: `/api/admin/${this.model_name}/get_page`,
      data: {
        page_num: page_num,
        filter_data: this.props.search_data || {},
        preset: this.props.search_preset || "",
      },
      success: (data) => {
        that.waitRenderReadyLock = false
        store.dispatch({
          type: 'admin_list_change',
          data: { ...data, page: page_num },
        })
      },
      error: (data) => {
        that.waitRenderReadyLock = false
        toastr.error(data.detail, "Ошибка обновления")
      },
    })
  }

  renderBreadcrumbs() {
    return (
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Домой</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/admin">Админка</Link>
          </li>
          <li className="breadcrumb-item active">{this.props.model_title}</li>
        </ol>
      </nav>
    )
  }

  waitRenderReady() {
    const dummyContent = super.waitRenderReady()
    if (dummyContent !== undefined) {
      return dummyContent
    }

    // Пока мета-данные текущей модели не пришли, выходим
    if (this.props.model_name !== this.model_name) {
      this.waitRenderReadyLock = true
      this.initMeta()
      return <div>Загрузка информации о модели</div>
    }

    // Пока список с объектами не пришел, выходим и ждем его
    if (this.props.list_items === undefined) {
      this.waitRenderReadyLock = true
      this.updateItems(this.props.list_page)
      return <div>Загрузка списка документов</div>
    }
  }

  renderContent() {
    let view = this

    function renderTh(attr_name) {
      const attr = view.props.attrs[attr_name]
      return <th key={attr_name}>{attr.title}</th>
    }

    function renderTr(row) {
      return <tr key={row.id}>
        <td><Link to={`/admin/${view.model_name}/${row.id}/`}>{row.id}</Link></td>
        {view.props.table_headers.map((attr_name) => { return renderTrTd(row, attr_name) })}
      </tr>
    }

    function renderTrTd(row, attr_name) {
      const attr = view.props.attrs[attr_name]
      return <td key={attr_name}>{format_attr_value(attr, row[attr_name])}</td>
    }

    if (this.props.list_items.length === 0) {
      return <div className="card mb-3">
        <div className="card-body p-3">
          Данные не найдены. Уточните условия отбора и поиска.
        </div>
      </div>
    }

    return [
      <div className="card mb-3" key="items">
        <div className="table-responsive text-nowrap">
          <table className='table table-sm'>
            <thead>
              <tr>
                <th key="id">#</th>
                {this.props.table_headers.map(renderTh)}
              </tr>
            </thead>
            <tbody>
              {this.props.list_items.map(renderTr)}
            </tbody>
          </table>
        </div>
      </div>,

      <Paginator current_page={this.props.list_page} key="paginator"
        count_pages={this.props.list_count_pages}
        setPage={this.updateItems} />
    ]
  }

}

export default wrapView(BaseAdminListView, stateToProps)
