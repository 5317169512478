/* global $ toastr */

import React, { Component } from 'react';
import AttrInput from '../lib/attr_input'
import { split_list } from '../lib/utils'
import { request } from '../lib/utils'
import { store } from '../../index'

/**
* Модальное окно поиска документов.
* В параметрах требует
* - schema: Схема формы (разделение параметров по группам)
* - data: Данные для инициализации формы поиска
* - choices: Все choces для полей формы
* - onSearch: callback
*/
export class ModalSearch extends Component {

  constructor(props) {
    super(props)

    this.onSearch = this.onSearch.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onClean = this.onClean.bind(this)

    this.state = {
      data: props.data || {},
      save_show_input: false,
      save_name: "",
    }
  }

  onChange(name, value) {
    this.setState({ data: { ...this.state.data, [name]: value } });
  }

  onClean() {
    this.props.onSearch({})
    this.setState({ data: {} })
    $('#modal_search').modal('hide')
  }

  onSearch() {
    this.props.onSearch(this.state.data)
    $('#modal_search').modal('hide')
  }

  renderSaveSearch() {
    const that = this;
    const state = this.state

    function searchNameChange(event) {
      that.setState({ save_name: event.target.value })
    }

    function saveSearch() {
      request({
        method: 'post',
        url: `/api/${that.props.project_name}/saved_search_add`,
        data: {
          title: state.save_name,
          data: state.data,
        },
        success: (data) => {
          store.dispatch({ type: `${that.props.project_name}_saved_search`, data })
          toastr.success("Поиск добавлен и доступен в разделе \"Отбор\"")
          that.setState({ save_show_input: !state.save_show_input })
        },
      })
    }

    function toggleSaveSearch() {
      that.setState({ save_show_input: !state.save_show_input })
    }

    return this.state.save_show_input ? (
      <div className="text-right">
        <div className="input-group">
          <input className="form-control" placeholder="Название для поиска" value={state.save_name} onChange={searchNameChange} />
          <button className="btn btn-primary" type="button" onClick={saveSearch}>
            <span className="ti ti-device-floppy me-1"></span>
            Сохранить
          </button>
        </div>
      </div>
    ) : (
      <button type="button" className="btn btn-primary p-2 me-1" onClick={toggleSaveSearch}>
        <span className="ti ti-device-floppy me-1"></span>
        Сохранить
      </button>
    )
  }

  render() {
    const that = this;
    const state = this.state
    const data = state.data

    function render_attr(attr) {
      return <AttrInput key={attr.name}
        data_type={attr.data_type}
        name={attr.name}
        title={attr.title}
        value={data[attr.name]}
        choice_name={attr.choice_name}
        choices={that.props.choices}
        inner_attrs={attr.inner_attrs}
        access="edit"
        onChange={that.onChange} />
    }

    function renderGroup(item, idx) {
      let group_title = item[0]
      let attrs = item[1]

      return <div key={idx}>
        {group_title && <div className="divider my-2">
          <div className="divider-text"><strong>{group_title}</strong></div>
        </div>}
        <div className="row">
          {split_list(attrs, 3).map((attrs, idx1) => {
            return <div className="col-md-4" key={idx1}>
              {attrs.map(render_attr)}
            </div>
          })}
        </div>
      </div>
    }

    return (
      <div className="modal fade" id="modal_search" tabIndex="-1" style={{ display: "none" }} aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel1">Поиск по параметрам</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {that.props.schema.map(renderGroup)}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary p-2 me-1" onClick={this.onSearch}>
                <span className="ti ti-search me-1"></span>
                Найти
              </button>
              <button type="button" className="btn btn-warning p-2 me-1" onClick={this.onClean}>
                <span className="ti ti-x me-1"></span>
                Сброс
              </button>
              <button type="button" className="btn btn-secondary p-2 me-1" data-bs-dismiss="modal">
                Закрыть
              </button>
              {this.renderSaveSearch()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
